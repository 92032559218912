<template>
	<components-error :error="error"/>
</template>

<script >
import ComponentsError from "@/components/admin/Error.vue"
export default {
	components:{ComponentsError},
	data(){
		return {
			query:{}
		}
	},
	computed:{
		error(){
			return {
				code:this.query.code || 404,
				message:decodeURIComponent(this.query.message || '页面不存在')
			}
		}
	},
	created() {
		this.query = this.$route.query;
	},
}
</script>
